<template>
  <div class="page bg-white">
    <div v-if="!state.userCanLogin" class="page d-flex justify-content-center align-content-center">
      <van-loading v-if="!state.wechatAuthError" class="justify-content-center align-content-center" color="#A5D63F"
        size="36" vertical>
        加载中
      </van-loading>
      <van-empty v-else image="error" :description="state.wechatAuthError">
        <van-button type="primary" plain @click="redoWechatAuth">重新授权</van-button>
      </van-empty>
    </div>
    <div v-else>
      <van-cell-group>
        <template #title>
          <img v-if="state.userinfo.avatar" :src="state.userinfo.avatar" alt="头像" width="20" height="20"
            class="rounded-circle" />
          {{ state.userinfo.nickname }}
        </template>

        <van-config-provider :theme-vars="themeVars">
          <div class="pt-2">
            <van-field v-model.trim="state.phone" type="tel" maxlength="11" label-width="4em" label="手机号"
              placeholder="请输入手机号码" :disabled="state.realPhone ? true : false" />
            <van-field v-model.number="state.smscode" type="number" maxlength="8" label-width="4em" label="验证码"
              placeholder="请输入4-8位验证码">
              <template #button>
                <van-button v-if="state.remainingTime > 0" size="small" disabled plain type="primary">{{
                  state.smsButtonText }}</van-button>
                <van-button v-else size="small" plain type="primary" @click="sendCode">{{ state.smsButtonText
                  }}</van-button>
              </template>
            </van-field>
          </div>
        </van-config-provider>
        <van-field v-if="state.has_invitation_code && !state.realPhone" v-model.trim="state.invitation_code" type="text"
          maxlength="16" label-width="4em" label="邀请码" :disabled="true" />
        <van-cell>
          <template #value>
            <van-checkbox v-model="state.argee">同意本平台用户协议和隐私政策</van-checkbox>
          </template>
        </van-cell>
      </van-cell-group>

      <van-row class="py-4">
        <van-col span="6"></van-col>
        <van-col span="12" class="text-center">
          <van-button type="primary" class="mb-2" block @click="login">{{
            submitButtonText
            }}</van-button>
          <a href="javascript:void(0);" @click="$router.push({ name: 'Agreement' })">用户协议</a>
          &amp;
          <a href="javascript:void(0);" @click="$router.push({ name: 'Privacy' })">隐私政策</a>
        </van-col>
        <van-col span="6"></van-col>
      </van-row>
    </div>
    <!-- end.else -->
  </div>
</template>

<script>
import {
  Row,
  Col,
  Field,
  Checkbox,
  Toast,
  Tab,
  Tabs,
  Notify,
  Image as VanImage,
} from "vant";
import { useStore } from "vuex";
import { onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { sendSmsCode } from "@/api/sms.service";
import { getAuthUrl, wechatAuth } from "@/api/wechat.service";
import { LOGIN, SET_AUTH } from "@/store/modules/auth";
import { hideWechatOptionMenu, fuzzMobile } from "@/utils/util";
import { createDeviceDetector } from "next-vue-device-detector";

export default {
  name: "Login",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [VanImage.name]: VanImage,
  },

  setup() {
    const device = createDeviceDetector();
    const { mobile, wechat } = device;

    // 暂时取消了密码登录，只用短信验证码登录，但是保留了 activeTab 的逻辑，如果需要密码登录，只需要回复UI部分即可
    const activeTab = ref(1);
    const submitButtonText = ref("登录");
    const themeVars = {
      tabsBottomBarColor: "#1989fa",
    };
    watch(
      () => activeTab.value,
      (newTabId) => {
        if (newTabId == 0) {
          submitButtonText.value = "登录";
          state.smscode = "";
        } else {
          submitButtonText.value = "注册/登录";
          state.password = "";
        }
      }
    );

    let timer = null;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      userCanLogin: false,
      wechatAuthError: null,
      userinfo: { phone: "" },
      realPhone: "", // 微信用户已经绑定过的手机, 此时的 phone 是脱敏显示的
      phone: "",
      password: "",
      smscode: "",
      invitation_code: "",
      has_invitation_code: false,
      argee: false,
      smsButtonText: "发送验证码",
      remainingTime: 0,
    });

    const redoWechatAuth = () => {
      const url = location.href.split("/login")[0];
      window.location.href = `${url}/login?reauth`;
    };

    const checkPhone = () => {
      if (!state.phone) {
        Toast({ message: "请输入手机号码", position: "bottom" });
        return false;
      }
      if (state.phone.length != 11) {
        Toast({ message: "手机号码不正确", position: "bottom" });
        return false;
      }
      const reg = /^1(\d){10}$/;
      if (state.realPhone) {
        if (!reg.test(state.realPhone)) {
          Toast({ message: "手机号码不正确(1)", position: "bottom" });
          return false;
        }
      } else if (!reg.test(state.phone)) {
        Toast({ message: "手机号码不正确(2)", position: "bottom" });
        return false;
      }
      return true;
    };

    const checkCode = () => {
      if (!state.smscode) {
        Toast({ message: "请输入验证码", position: "bottom" });
        return false;
      }
      if (state.smscode.toString().length < 4) {
        Toast({ message: "验证码不正确", position: "bottom" });
        return false;
      }
      return true;
    };

    const tickTick = () => {
      // 定时器 90 秒后可重发短信验证码
      state.remainingTime = 90;
      timer = setInterval(() => {
        if (state.remainingTime == 0) {
          state.smsButtonText = "发送验证码";
          clearInterval(timer);
        } else {
          state.remainingTime--;
          state.smsButtonText = `${state.remainingTime}秒后可重发`;
        }
      }, 1000);
    };

    const sendCode = async () => {
      if (!checkPhone()) {
        return;
      }
      try {
        const resp = await sendSmsCode(
          state.realPhone ? state.realPhone : state.phone
        );
        if (resp.result.BizId) {
          tickTick();
          Toast({ message: "已发送", position: "bottom" });
          state.smscode = "";
        } else {
          Notify({ type: "warning", message: resp.msg });
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Notify({ type: "warning", message: msg ? msg : "发送验证码失败" });
      }
    };

    const login = async () => {
      if (!state.argee) {
        return Toast({ message: "请先同意用户协议", position: "bottom" });
      }
      if (!checkPhone()) {
        return;
      }
      if (activeTab.value == 0) {
        if (!state.password || state.password.length < 8) {
          return Toast({
            message: "请输入不低于8位的密码",
            position: "bottom",
          });
        }
      } else if (activeTab.value == 1) {
        if (!checkCode()) {
          return;
        }
      }
      store
        .dispatch(LOGIN, {
          phone: state.realPhone ? state.realPhone : state.phone,
          password: state.password,
          code: state.smscode,
          invitation_code: state.invitation_code,
          is_mobile: mobile ? 1 : 0,
          is_wechat: wechat ? 1 : 0,
          openid: state.userinfo?.openid,
        })
        .then(() => {
          Toast({ message: "欢迎登录", position: "bottom", duration: 3500 });

          let redirect = window.sessionStorage.getItem("login_redirect_url");
          window.sessionStorage.removeItem("preauth");
          window.sessionStorage.removeItem("login_redirect_url");

          if (redirect) {
            window.location.href = redirect;
          } else {
            redirect = route.query.redirect || "/";
            router.push(decodeURIComponent(redirect));
          }
        })
        .catch((error) => {
          // 返回 401 会删除 preauth, 详见 src/store/modules/auth.js
          Notify({ type: "danger", message: error });
        });
    };

    // 先进行微信登录授权, 然后再使用手机验证码登录
    const preauth = async (queryCode, queryState) => {
      try {
        // 如果当前 session 已经调用成功 wechatAuth() 了, 则不再调用, 因为 queryCode 已被使用
        // 查看用户协议在返回页面时, 如果再次调用 wechatAuth() 微信授权会报错, 影响用户使用体验
        // 登陆成功后记得手动清理掉此 sessionStorage
        const preauth = window.sessionStorage.getItem("preauth");
        if (preauth) {
          state.userCanLogin = true;
          state.wechatAuthError = null;
          state.userinfo = JSON.parse(preauth);
          if (state.userinfo.phone) {
            state.phone = fuzzMobile(state.userinfo.phone);
            state.realPhone = state.userinfo.phone;
          }

          if (state.userinfo.parent_invitation_code) {
            state.invitation_code = state.userinfo.parent_invitation_code;
            state.has_invitation_code = true;
          }

          return;
        }

        const { result } = await wechatAuth(
          queryCode,
          queryState,
          state.invitation_code
        );
        const forceSmsLogin = store.getters.forceSmsLogin;
        if (result.session_id && !forceSmsLogin) {
          store.commit(SET_AUTH, result);
          Toast({
            message: "欢迎登录",
            position: "bottom",
            duration: 3500,
          });
          window.sessionStorage.removeItem("preauth");
          const redirect = route.query.redirect || "/";
          router.push(decodeURIComponent(redirect));
        } else {
          if (result.parent_invitation_code) {
            state.invitation_code = result.parent_invitation_code;
            state.has_invitation_code = true;
          } else {
            state.has_invitation_code = false;
          }
          state.userCanLogin = true;
          state.wechatAuthError = null;
          state.userinfo = result; // 备用, 可用于展示用户头像和昵称
          state.phone = fuzzMobile(result.phone);
          state.realPhone = result.phone;
          window.sessionStorage.setItem("preauth", JSON.stringify(result));
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        state.wechatAuthError = msg ? msg : "获取微信授权失败";
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const jumptoWechat = () => {
      const callback_url = location.href.split("#")[0];
      const linker = callback_url.indexOf("?") == -1 ? "?" : "&";
      const redirect_url = getAuthUrl(
        `${callback_url}${linker}_t=${new Date().getTime()}`
      );
      window.location.href = redirect_url;
    };

    onMounted(() => {
      const inviteData = JSON.parse(window.localStorage.getItem("invite"));
      if (inviteData && inviteData.code) {
        state.invitation_code = inviteData.code;
        state.has_invitation_code = true;
      }
      if (wechat) {
        const { code, state } = route.query;
        if (code && state) {
          preauth(code, state);
          hideWechatOptionMenu();
        } else {
          setTimeout(() => {
            jumptoWechat();
          }, 1200);
        }
      } else if (mobile) {
        // 可以在移动端浏览器(非微信内置)中访问
        state.userCanLogin = true;
        state.userinfo = {
          nickname: "用户登录",
          headimgurl: "",
        };
      } else {
        // PC端的访问引导至微信
        Toast({
          message: "请在微信内访问此页面",
          position: "top",
          onClose: () => {
            jumptoWechat();
          },
        });
      }
    });

    onUnmounted(() => {
      try {
        clearInterval(timer);
      } catch (error) {
        // ...
      }
    });

    return {
      activeTab,
      submitButtonText,
      themeVars,
      state,
      redoWechatAuth,
      sendCode,
      login,
    };
  },
};
</script>
